import React from 'react';
import { reaction } from 'mobx';


const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const OverallScene = React.lazy(() => import('./views/Dashboard/DashboardScene'));
const QuotationScene = React.lazy(() => import('./views/Quotation/QuotationScene'));
const QuotationEdit = React.lazy(() => import('./views/Quotation/EditQuotation'));
//const QuotationCreate = React.lazy(() => import('./views/Quotation/CreateQuotation'));
const QuotationCreate = React.lazy(() => import('./views/Quotation/CreateQuotation'));
const PurchaseScene = React.lazy(() => import('./views/SupplierPO/PurchaseScene'));
const CreateSupplierPOScene = React.lazy(() => import('./views/SupplierPO/CreateSupplierPOScene'));
const PRScene = React.lazy(() => import('./views/PR/PurchaseRequisitionScene'));
const PRCreate = React.lazy(() => import('./views/PR/CreatePR'));
const PRDetail = React.lazy(() => import('./views/PR/DetailPR'));
const CustomerPOScene = React.lazy(() => import('./views/CustomerPO/CustomerPOScene'));
const CustomerPODetail = React.lazy(() => import('./views/CustomerPO/DetailCustomerPOScene'));
const EditSupplierPOScene = React.lazy(() => import('./views/SupplierPO/EditSupplierPOScene'));
const GoodreceiveScene = React.lazy(() => import('./views/Dashboard/GoodreceiveScene'));
const UserScene = React.lazy(() => import('./views/Users/User'));
const ChangpasswordScene = React.lazy(() => import('./views/Users/ChangpasswordScene'));
const Management = React.lazy(() => import('./views/Management/Management'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome/FontAwesome'));
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/overall', exact: true, name: 'Overall', component: OverallScene },
  { path: '/users', exact: true, name: 'User', component: UserScene },
  { path: '/changpassword', exact: true, name: 'Changpassword', component: ChangpasswordScene },
  { path: '/dashboard/goodreceive/:id', name: 'Goodreceive', component: GoodreceiveScene },
  { path: '/supplierpo', exact: true, name: 'SupplierPO', component: PurchaseScene },
  { path: '/supplierpo/create/:id', name: 'Create', component: CreateSupplierPOScene },
  { path: '/supplierpo/edit/:id', name: 'Edit', component: EditSupplierPOScene },
  { path: '/quotation', exact: true, name: 'Quotation', component: QuotationScene },
  //{ path: '/quotation/new', name: 'CreateQuotation', component: QuotationCreate },
  { path: '/quotation/edit/:id', name: 'Edit', component: QuotationEdit },
  { path: '/quotation/create/:id', name: 'Create', component: QuotationCreate },

  { path: '/purchaserequisition', exact: true, name: 'PR', component: PRScene },
  { path: '/purchaserequisition/new', name: 'Create', component: PRCreate },
  { path: '/purchaserequisition/:id', name: 'Detail', component: PRDetail },


  { path: '/customerpo', exact: true, name: 'CustomerPO', component: CustomerPOScene },
  { path: '/customerpo/detail/:id', name: 'Detail', component: CustomerPODetail },
  { path: '/manage', exact: true, name: 'Manage', component: Management },

  { path: '/FontAwesome', exact: true, name: 'FontAwesome', component: FontAwesome },


];

export default routes;
