import { observable, action, computed } from 'mobx'
export const KEY = 'DkdL8OVPdp';
class User {
    @observable role = '';
    @observable auth = false;
    @observable name = '';
    @observable tel = '';
    @observable username = '';
    @observable over_all_active = 0
    constructor() {
        this.initial();
    }
    @action
    async initial() {
        try {
            const res = await localStorage.getItem(KEY);
            console.log(res)
            if (res) {
                const obj = JSON.parse(res);
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const element = obj[key];
                        this[key] = element;
                    }
                }
                this.auth = true;
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action
    async login(result) {
        console.log(result)
        let role = result.role
        let name = result.name
        let tel = result.tel
        let username = result.username
        let over_all_active = result.over_all_active

        try {
            console.log('login')
            await localStorage.setItem(KEY, JSON.stringify({ role, name, tel, username, over_all_active }));
            this.username = username
            this.role = role;
            this.name = name
            this.tel = tel
            this.auth = true;
            this.over_all_active = over_all_active
        } catch (error) {
            console.log(error)
        }
    }
    async signOut() {
        try {
            await localStorage.removeItem(KEY);
            this.auth = false;
            this.role = '';
            this.name = '';
            this.tel = '';
            this.username = '';
            this.over_all_active = 0;

            console.log('sign out');

        } catch (error) {
            console.log(error)
        }
    }



}

export default new User(); 