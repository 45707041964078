import React, { Component, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import user from '../mobx/user';
import {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppBreadcrumb2 as AppBreadcrumb,
    AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../_nav';
// routes config
import routes from '../routes';

const DefaultAside = React.lazy(() => import('../containers/DefaultLayout/DefaultAside'));
const DefaultFooter = React.lazy(() => import('../containers/DefaultLayout/DefaultFooter'));
const DefaultHeader = React.lazy(() => import('../containers/DefaultLayout/DefaultHeader'));



export default withRouter((props) => {

    useEffect(() => {
        console.log('app')
        console.log(user.auth)
        if (!user.auth) {
            console.log('if')
            return props.history.push('/login')
        }

        // const findPath = menu[user.role].routes.findIndex(el => props.location.pathname.includes(el.path));
        // if (findPath === -1)
        //   return props.history.replace('/')


    }, null)//[props.location.pathname]
    const loading = () =>
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    const signOut = () => {
        user.signOut()
        props.history.push('/login')
    }
    const onUser = () => {
        props.history.push('/users')
    }
    const onChangpassword = () => {
        props.history.push('/changpassword')
    }
    const onManagement = () => {
        props.history.push('/manage')
    }


    return (
        <div className="app">
            <AppHeader fixed>
                <Suspense fallback={loading()}>
                    <DefaultHeader onLogout={e => signOut()} onUser={e => onUser()} onChangpassword={e => onChangpassword()} onManagement={e => onManagement()} />
                </Suspense>
            </AppHeader>
            <div className="app-body" >
                <AppSidebar fixed display="lg">
                    <AppSidebarHeader />
                    <AppSidebarForm />
                    <Suspense>
                        <AppSidebarNav navConfig={navigation} {...props} router={router} />
                    </Suspense>
                    <AppSidebarFooter />
                    <AppSidebarMinimizer />
                </AppSidebar>
                <main className="main">
                    <AppBreadcrumb appRoutes={routes} router={router} />
                    <Container fluid>
                        <Suspense fallback={loading()}>
                            <Switch>
                                {routes.map((route, idx) => {
                                    return route.component ? (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={props => (
                                                <route.component {...props} />
                                            )} />
                                    ) : (null);
                                })}
                                <Redirect from="/" to="/dashboard" />
                            </Switch>
                        </Suspense>
                    </Container>
                </main>
                <AppAside fixed>
                    <Suspense fallback={loading()}>
                        <DefaultAside />
                    </Suspense>
                </AppAside>
            </div>
            <AppFooter>
                <Suspense fallback={loading()}>
                    <DefaultFooter />
                </Suspense>
            </AppFooter>
        </div>
    );
})

